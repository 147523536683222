import type { FC } from "react"
import React from "react"
import flatten from "lodash.flatten"
import type { StackProps } from "@chakra-ui/react"
import { Flex, Stack, Center, Skeleton, Button } from "@chakra-ui/react"

import { ROUTES } from "common/constants/routes"
import type {
  ChainId,
  AccountId,
  GovernanceProposalsQuery,
  GovernanceProposalsQueryVariables,
  Organization,
  Proposal,
  Vote,
} from "query/graphql"
import {
  GovernanceProposalsDocument,
  ProposalsSortBy,
  useGovernanceProposalsVotesQuery,
} from "query/graphql"
import GovernanceProposalsTable from "governance/components/GovernanceProposalsTable"
import { useInfiniteCursorQuery } from "query/hooks/useInfiniteQuery"
import Link from "common/components/Link"
import LoadMoreButton from "common/components/LoadMoreButton"
import { generateArrayOfNumbers } from "common/helpers/array"
import CardContainer from "common/components/CardContainer"
import {
  FeatureFlag as GovernanceFeatureFlag,
  useGovernanceContext,
} from "governance/context"
import GovernanceProposalsStats from "governance/components/GovernanceProposalsStats"
import { getWhiteLabelRoute } from "whitelabel/utils/breadcrumb"
import {
  FeatureFlag,
  useOrganization,
} from "organization/providers/OrganizationProvider"
import { useAddress } from "web3/hooks/useAddress"

type Props = {
  slug: string
  chainId?: ChainId
  governorIds: AccountId[]
  displayTop?: boolean
  displayCreateProposal?: boolean
  displayGovernorColumn?: boolean
  isWhiteLabel: boolean
  organization: Organization
}

const GovernanceProposalsList: FC<Props & StackProps> = ({
  slug,
  chainId,
  governorIds,
  displayTop = true,
  displayCreateProposal = true,
  displayGovernorColumn = true,
  isWhiteLabel,
  organization,
  ...stackProps
}) => {
  const voterAddress = useAddress()

  const isArbitrum = slug === "arbitrum"

  const { isFeatureFlagOn } = useOrganization()

  const { isFeatureFlagOn: isGovernanceFeatureFlagOn } = useGovernanceContext()

  const hideCreateProposal = isGovernanceFeatureFlagOn(
    GovernanceFeatureFlag.HideCreateProposal,
  )

  const isLegacyLayoutEnabled = isFeatureFlagOn(FeatureFlag.LegacyLayout)

  const showCreateProposalButton =
    displayCreateProposal && !hideCreateProposal && isLegacyLayoutEnabled

  const PAGE_SIZE = displayTop && !isArbitrum ? 8 : 10

  const { data, isLoading, fetchNextPage, hasNextPage } =
    useInfiniteCursorQuery<
      GovernanceProposalsQuery,
      GovernanceProposalsQueryVariables
    >({
      document: GovernanceProposalsDocument,
      sectionName: "GovernanceProposals",
      nextPagePath: "proposals",
      variables: {
        input: {
          filters: {
            organizationId: organization.id,
          },
          sort: {
            sortBy: ProposalsSortBy.Id,
            isDescending: true,
          },
          page: { limit: PAGE_SIZE },
        },
      },
    })

  const pages = data?.pages ?? []
  const allProposals = pages
    .map((page) => page?.proposals?.nodes as Proposal[])
    .filter((proposal) => proposal !== undefined)
  const proposals = flatten(allProposals)
  const proposalIds = proposals.map((proposal) => proposal.id)

  const { data: votesData } = useGovernanceProposalsVotesQuery(
    {
      input: {
        filters: {
          proposalIds,
          voter: voterAddress,
        },
      },
    },
    { enabled: Boolean(voterAddress) && proposalIds && proposalIds.length > 0 },
  )

  const isLoadMoreDisabled = !hasNextPage

  const handleLoadMoreClick = () => fetchNextPage()

  if (isLoading && Boolean(chainId)) {
    return (
      <Stack as="section" mb={6} spacing={6} {...stackProps}>
        <Flex border="gray.light" direction="column" rounded="sm" w="full">
          <Flex borderBottom="gray.light" h="2.668125rem" w="full">
            <Skeleton h="full" w="full" />
          </Flex>
          {generateArrayOfNumbers(4).map((number) => (
            <Flex
              key={`recent_vote_${number}`}
              borderBottom="gray.light"
              h="4.856875rem"
              w="full"
            >
              <Skeleton h="full" w="full" />
            </Flex>
          ))}
          <Flex h={12} w="full">
            <Skeleton h="full" w="full" />
          </Flex>
        </Flex>
      </Stack>
    )
  }

  const hasProposals = proposals && proposals.length

  const voterVotesPage = votesData?.votes?.nodes ?? []
  const voterVotes = voterVotesPage
    .filter((voter) => voter !== undefined)
    .map((voter) => voter as Vote)

  return (
    <CardContainer
      isTableView
      borderTop={isLegacyLayoutEnabled ? undefined : 0}
      borderTopRadius={isLegacyLayoutEnabled ? undefined : 0}
      pb={2}
      title={isLegacyLayoutEnabled ? "Proposals" : undefined}
      topRight={
        <>
          {chainId ? (
            <GovernanceProposalsStats organizationId={organization.id} />
          ) : null}
          {showCreateProposalButton ? (
            <Flex
              flex={1}
              ml={{ base: 0, sm: 4 }}
              my={{ base: 4, sm: 0 }}
              width="100%"
            >
              <Link
                href={
                  isWhiteLabel
                    ? getWhiteLabelRoute(
                        ROUTES.governance.proposal.create(slug),
                      )
                    : ROUTES.governance.proposal.create(slug)
                }
                width="100%"
              >
                <Button variant="secondary" width="100%">
                  Create new proposal
                </Button>
              </Link>
            </Flex>
          ) : null}
        </>
      }
    >
      <Stack as="section" spacing={2} {...stackProps}>
        <GovernanceProposalsTable
          displayGovernorColumn={displayGovernorColumn}
          isMultiGov={governorIds.length > 1}
          isWhiteLabel={isWhiteLabel}
          organization={organization}
          proposals={proposals}
          slug={slug}
          voterAddress={voterAddress}
          voterVotes={voterVotes}
        />
        {displayTop && hasProposals ? (
          <Center>
            <Link
              className="no-underline"
              href={
                isWhiteLabel
                  ? getWhiteLabelRoute(ROUTES.governance.proposals.list(slug))
                  : ROUTES.governance.proposals.list(slug)
              }
            >
              <Button
                data-qa="governanceproposals-viewall-btn"
                size="sm"
                variant="tertiary"
              >
                View all
              </Button>
            </Link>
          </Center>
        ) : null}
        {!displayTop && hasProposals && !isLoadMoreDisabled ? (
          <Center mt={1} mx={2}>
            <LoadMoreButton
              isDisabled={isLoadMoreDisabled}
              width="100%"
              onClick={handleLoadMoreClick}
            />
          </Center>
        ) : null}
      </Stack>
    </CardContainer>
  )
}

export default GovernanceProposalsList
