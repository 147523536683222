import type { ReactNode, ReactElement } from "react"
import { useContext, createContext } from "react"

import type { FeatureState, Governor } from "query/graphql"
import { useGovernanceFeaturesQuery } from "query/graphql"

export enum FeatureFlag {
  // Force to dislay Execute button even if regular conditions prevent it
  DisplayExecuteBtnOnActiveStatus = "DISPLAY_EXECUTE_BTN_ON_ACTIVE_STATUS",

  // Hide Create Proposal button and prevent to access page via URL
  HideCreateProposal = "HIDE_CREATE_PROPOSAL",

  // Hide Cancel Proposal button
  HideCancelProposal = "HIDE_CANCEL_PROPOSAL",

  // Enable council feature
  Council = "COUNCIL",

  L2Execution = "L2EXECUTION",

  // Hide Simulation on Impact Overview
  HideSimulation = "HIDE_SIMULATION",

  // This governor is OZ governor v4.9 so the cancel function receives multiple params
  OzGovernorCancelWithMultipleParams = "OZ_GOVERNOR_CANCEL_WITH_MULTIPLE_PARAMS",
}

type GovernanceContextValues = {
  isFeatureFlagOn: (name: string) => boolean
  governor?: Pick<Governor, "id" | "slug">
}

const GovernanceContext = createContext<GovernanceContextValues | undefined>(
  undefined,
)

export const useGovernanceContext = (): GovernanceContextValues => {
  const context = useContext(GovernanceContext)

  if (!context)
    throw new Error("GovernanceContext must be used within GovernanceProvider")

  return context
}

export const isFeatureFlagOn = (
  features: FeatureState[] | null | undefined,
  name: string,
) => {
  // find feature of this Governance looking by name
  const featureState = features?.find(
    (feature: FeatureState) =>
      feature.name.toLowerCase() === name.toLowerCase(),
  )

  if (featureState) return featureState.enabled

  return false
}

type GovernanceProviderProps = {
  children: ReactNode
  governor?: Pick<Governor, "id" | "slug">
}
const GovernanceProvider = ({
  children,
  governor,
}: GovernanceProviderProps): null | ReactElement => {
  const governorId = governor?.id
  const { data } = useGovernanceFeaturesQuery(
    {
      input: {
        id: governorId ?? "",
      },
    },
    { enabled: Boolean(governorId) },
  )

  const _isFeatureFlagOn = (name: string) => {
    return isFeatureFlagOn(data?.governor?.features, name)
  }

  const value = { isFeatureFlagOn: _isFeatureFlagOn, governor }

  return (
    <GovernanceContext.Provider value={value}>
      {children}
    </GovernanceContext.Provider>
  )
}

export default GovernanceProvider
