import type { FC } from "react"
import React from "react"
import { Text, Flex } from "@chakra-ui/react"
import dayjs from "dayjs"

import type { Proposal } from "query/graphql"

type ProposalVotingStartProps = {
  proposal: Proposal
}

const ProposalVotingStart: FC<ProposalVotingStartProps> = ({ proposal }) => {
  const block = proposal.start
  const getVotingStartsLabel = () => {
    if (block?.timestamp) {
      // Display correct time only if block's timestamp is in a future
      // This prevents displaying "Voting starts a minute ago" in DAOs
      // what has a proposal delay of seconds
      if (dayjs().isBefore(dayjs(block.timestamp))) {
        const dateLabel = dayjs(block.timestamp).fromNow()

        return `Voting starts ${dateLabel}`
      }

      return "Voting starts soon"
    }

    return "Calculating voting start..."
  }

  const votingStartsLabel = getVotingStartsLabel()

  return (
    <Flex
      direction="row"
      fontSize="sm"
      justifyContent="flex-end"
      textAlign="right"
      width="100%"
    >
      <Text
        align="right"
        color="gray.500"
        textStyle="body.regular.md"
        width="max-content"
      >
        {votingStartsLabel}
      </Text>
    </Flex>
  )
}

export default ProposalVotingStart
