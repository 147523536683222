import type { FC } from "react"
import React from "react"
import { Text, HStack } from "@chakra-ui/react"

import type { Governor } from "query/graphql"
import { useGovernanceProposalsStatsQuery } from "query/graphql"

type GovernanceProposalsStatsProps = {
  organizationId: string
}
const GovernanceProposalsStats: FC<GovernanceProposalsStatsProps> = ({
  organizationId,
}) => {
  const { data } = useGovernanceProposalsStatsQuery({
    input: { filters: { organizationId } },
  })

  if (!data) {
    return null
  }

  const { governors } = data

  const passedProposalsCount = (governors.nodes as Governor[]).reduce(
    (currentTotal, governor) => {
      return currentTotal + governor.proposalStats.passed
    },
    0,
  )

  const failedProposalsCount = (governors.nodes as Governor[]).reduce(
    (currentTotal, governor) => {
      return currentTotal + governor.proposalStats.failed
    },
    0,
  )

  return (
    <HStack
      align="center"
      display={{ base: "none", lg: "flex" }}
      mr={2}
      spacing={4}
    >
      <HStack spacing={2}>
        <Text color="gray.500" textStyle="label">
          {passedProposalsCount >= 0 ? passedProposalsCount : 0}
        </Text>
        <Text color="green.500" textStyle="label">
          Passed
        </Text>
      </HStack>
      <HStack spacing={2}>
        <Text color="gray.500" textStyle="label">
          {failedProposalsCount >= 0 ? failedProposalsCount : 0}
        </Text>
        <Text color="red.500" textStyle="label">
          Failed
        </Text>
      </HStack>
    </HStack>
  )
}

export default GovernanceProposalsStats
