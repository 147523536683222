import type { FC } from "react"
import React from "react"
import isEqual from "lodash.isequal"

import type { Column } from "ui/components/Table"
import Table from "ui/components/Table"
import { useDevice } from "common/hooks/useDevice"
import type { Vote } from "query/graphql"
import { ProposalStatus, type Organization, type Proposal } from "query/graphql"
import { ProposalIdentity } from "common/components/columns/ProposalIdentity"
import ProposalVotesFor from "common/components/columns/ProposalVotesFor"
import ProposalVotesAgainst from "common/components/columns/ProposalVotesAgainst"
import ProposalVotesTotal from "common/components/columns/ProposalVotesTotal"
import ProposalVotingStart from "common/components/columns/ProposalVotingStart"

type Props = {
  isMultiGov?: boolean
  displayTableHeader?: boolean
  proposals: Proposal[]
  isWhiteLabel: boolean
  organization: Organization
  displayGovernorColumn?: boolean
  voterVotes?: Vote[]
  slug: string
  voterAddress?: string
}

const GovernanceProposalsTable: FC<Props> = ({
  proposals,
  isMultiGov = false,
  displayTableHeader = true,
  isWhiteLabel,
  organization,
  displayGovernorColumn = true,
  voterVotes,
  slug,
  voterAddress,
}) => {
  const { onLargeDevice, onLittleDevice } = useDevice()

  const findVoterVote = (governorId: string, proposalId: string) => {
    if (!voterAddress) return undefined

    const voterVote = voterVotes?.find(
      (vote) =>
        isEqual(vote.proposal.id, proposalId) &&
        isEqual(vote.proposal.governor.id, governorId),
    )

    return voterVote
  }

  const generateLargeColumns = (proposals: Proposal[]): Column[] => {
    const columns = [
      {
        title: "Proposal",
        isNumeric: false,
        cells: proposals.map((proposal) => {
          return (
            <ProposalIdentity
              key={`governance-proposals-identity-${proposal.id}`}
              decimals={proposal.governor.token.decimals}
              displayGovernorName={isMultiGov && displayGovernorColumn}
              governor={proposal.governor}
              isWhiteLabel={isWhiteLabel}
              organization={organization}
              proposal={proposal}
              slug={slug}
              voterVote={findVoterVote(proposal.governor.id, proposal.id)}
            />
          )
        }),
      },
      {
        title: "Votes for",
        isNumeric: false,
        minW: { base: "60px", xl: "100px" },
        cells: proposals.map((proposal) => {
          if (proposal.status === ProposalStatus.Pending) return <></>

          return (
            <ProposalVotesFor
              key={`governance-proposals-votes-${proposal.id}`}
              decimals={proposal.governor.token.decimals}
              voteStats={proposal.voteStats}
            />
          )
        }),
      },
      {
        title: "Votes against",
        isNumeric: false,
        minW: { base: "60px", xl: "100px" },
        cells: proposals.map((proposal) => {
          if (proposal.status === ProposalStatus.Pending) return <></>

          return (
            <ProposalVotesAgainst
              key={`governance-proposals-votes-${proposal.id}`}
              decimals={proposal.governor.token.decimals}
              voteStats={proposal.voteStats}
            />
          )
        }),
      },
      {
        title: "Total votes",
        isNumeric: true,
        cells: proposals.map((proposal) => {
          if (proposal.status === ProposalStatus.Pending) {
            return (
              <ProposalVotingStart
                key={`governance-proposals-total-votes-${proposal.id}`}
                proposal={proposal}
              />
            )
          }

          return (
            <ProposalVotesTotal
              key={`governance-proposals-total-votes-${proposal.id}`}
              decimals={proposal.governor.token.decimals}
              proposal={proposal}
            />
          )
        }),
      },
    ]

    return columns
  }

  const generateLittleColumns = (proposals: Proposal[]): Column[] => {
    return [
      {
        title: "Proposal",
        isNumeric: false,
        cells: proposals.map((proposal) => {
          return (
            <ProposalIdentity
              key={`governance-proposals-identity-${proposal.id}`}
              decimals={proposal.governor.token.decimals}
              governor={proposal.governor}
              organization={organization}
              proposal={proposal}
              slug={slug}
              voterVote={findVoterVote(proposal.governor.id, proposal.id)}
            />
          )
        }),
      },
    ]
  }

  const largeColumns: Column[] = generateLargeColumns(proposals)
  const littleColumns: Column[] = generateLittleColumns(proposals)

  return (
    <>
      <Table
        columns={largeColumns}
        display={onLargeDevice}
        displayBorderTop={false}
        displayTableHeader={displayTableHeader}
        emptyMessage="No proposals created yet"
        id="governance-proposals"
      />
      <Table
        columns={littleColumns}
        display={onLittleDevice}
        displayBorderTop={false}
        displayTableHeader={displayTableHeader}
        emptyMessage="No proposals created yet"
        id="governance-proposals"
      />
    </>
  )
}

export default GovernanceProposalsTable
